<template>
  <div class="traveladata">
    <Header class="hide" />
    <Headers class="dispy" />
    <div class="bar">
      <BannerImage rulename="enterprise_banner" />
      <div class="w">
        <div class="info">
          <div class="tuo">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }"
                ><i class="el-icon-s-home"></i>首页</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-for="(item, index) in crumbdata"
                :key="index"
              >
                <span v-if="!item.url">{{ item.name }}</span>
                <router-link v-else :to="item.url">{{ item.name }}</router-link>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row>
            <el-col :md="12">
              <img
                :src="`/jeecg-boot/${result.theLocal}`"
                alt=""
                class="tuo-img"
              />
            </el-col>
            <el-col :md="12">
              <div class="doc">
                <p class="title">{{ result.theTitle }}</p>
                <p class="doc-text">{{ result.theType }}</p>
              </div>
              <div class="op">
                <p class="boder1"></p>
                <p class="phone">联系电话：{{ result.theTel }}</p>
                <p class="boder1"></p>

                <p class="phone">地　　址：{{ result.theAdd }}</p>
                <p class="boder1"></p>

                <p class="phone">开业时间：{{ result.theTime }}</p>
                <p class="boder1"></p>

                <p class="phone">服务特点：{{ result.theTrait }}</p>
                <p class="boder1"></p>

                <p class="phone">业务范围：{{ result.theScope }}</p>
                <p class="boder1"></p>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :md="24">
            <div class="offer">
              <div class="title">旅行社详情</div>
              <el-divider></el-divider>
              <div class="video" v-html="result.theDetails"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
    <!-- 点评弹框 -->
    <Commentbox ref="commentv" @change="commonHandler" />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import BannerImage from "@/components/main/BannerImage";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import { getAction, postAction } from "@/api/manage.js";
import Commentbox from "@/components/Commentbox";
export default {
  components: {
    Header,
    Footer,
    Pagi,
    Breadcrumb,
    Commentbox,
    Headers,
    BannerImage
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      crumbdata: [
        { name: "旅游企业", url: "" },
        { name: "旅行社", url: "/travelagency" },
      ],
      result: {},
    };
  },
  methods: {
    loadData() {
      getAction("/lvxs/tourCommunity/queryById", {
        id: this.$route.query.id,
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.traveladata {
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
  .info {
    background-color: #ffffff;
    margin-top: 30px;
    margin-bottom: 25px;
    padding-top: 16px;
    .tuo {
      margin-left: 18px;
    }
    .tuo-img {
      width: 511px;
      height: 385px;
      margin-left: 17px;
      margin-top: 21px;
      margin-bottom: 26px;
    }
    .doc {
      .title {
        font-family: MicrosoftYaHei;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: 0px;
        color: #333333;
        margin-top: 45px;
        margin-left: 41px;
      }
      .doc-text {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: 0px;
        color: #6EB400;
        margin-top: 26px;
        margin-left: 41px;
      }
    }
    .op {
      .phone {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #666666;
        margin-left: 41px;
      }
      .boder1 {
        border: 1px dashed #dbdbdb;
        margin: 13px 13px;
        margin-left: 41px;
      }
    }
  }
  .offer {
    background-color: #ffffff;
    padding-bottom: 30px;
    margin-bottom: 500px;
    .video {
      .doc {
        width: 366px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        color: #666666;
        margin-top: 56px;
        margin-bottom: 44px;
        word-wrap: break-word;
        word-break: normal;
      }
      .titles {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        color: #666666;
      }
      .v-img {
        width: 576px;
        height: 384px;
        margin-top: 47px;
        margin-left: 59px;
      }
    }
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .el-divider--horizontal {
      width: 96%;
      margin: 0 0 0 16px;
    }
    .video {
      padding: 15px;
    }
  }
  .details {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .el-divider--horizontal {
      width: 96%;
      margin: 0 0 0 16px;
    }
    .doc {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 0px;
      color: #666666;
      width: 728px;
      padding: 20px;
    }
  }
  .comment {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    padding-bottom: 38px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .el-divider--horizontal {
      width: 96%;
      margin: 0 0 0 16px;
    }
    .el-button--success {
      margin: 20px;
    }
    .character {
      .tou {
        .xx {
          margin-left: 70px;
          margin-top: -53px;
        }
        .touxiang {
          width: 38px;
          height: 38px;
          border-radius: 15px;
          margin: 0px 8px 17px 20px;
        }
        .name {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #333333;
        }
        .time {
          font-family: MicrosoftYaHei;
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #999999;
          margin-left: 10px;
        }
      }
      .favorable {
        padding: 0 68px;
        .commonbody {
          font-size: 14px;
          color: #666666;
          margin-bottom: 10px;
        }
        .favorable-image {
          width: 100%;
          height: 82px;
          border-radius: 3px;
        }
      }
    }
  }
  .hotel {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    padding-bottom: 15px;
    margin-left: 24px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .titles {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
      float: right;
      margin-top: -41px;
      margin-right: 21px;
    }
    .el-divider--horizontal {
      width: 91%;
      margin: 0 0 0 16px;
    }
    .tup {
      padding: 15px 15px 0 15px;
      position: relative;
      height: 217px;
      .bguan1 {
        width: 100%;
        height: 200px;
      }
      .name {
        height: 35px;
        background-color: #00000096;
        text-align: center;
        position: relative;
        top: -39px;
        span {
          font-family: MicrosoftYaHei;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 29px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
