<template>
    <div>
        <el-dialog
            title="点评"
            :visible="commvisb"
            width="600px"
            :before-close="handleClose">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="commentBody">
                    <el-input type="textarea" v-model="form.commentBody" :rows="4" placeholder="您要评论什么" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <PictureMultiple class="picmult" @change="picHandler" @deleteChange="deleteHandler" />
            </el-form>
            <el-link type="success">您的评论需要审核,请等待</el-link>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="small" @click="success('form')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import PictureMultiple from '@/components/PictureMultiple'
    export default {
        components: {
            PictureMultiple
        },
        mounted() {
            let userid = localStorage.getItem("userid")
            let rid = this.$route.query.id
            this.form.rid = rid
            this.form.uid = userid
        },
        data() {
            return {
                commvisb: false,
                form: {
                    rid: '',
                    uid: '',
                    commentBody: '',
                    pic: '',
                    tag: '',
                },
                rules: {
                    commentBody: [
                        { required: true, message: '评论不能为空', trigger: 'change' }
                    ]
                }
            };
        },
        methods: {
            open(tags){
                this.commvisb = true
                this.form.tag = tags
            },
            handleClose(done) {
                this.commvisb = false
            },
            picHandler(val){
                this.form.pic += val+','
            },
            deleteHandler(val){
                // this.form.pic.indexOf(val)
            },
            success(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$emit('change',this.form)
                        this.$refs[formName].resetFields();
                         this.form.pic = ''
                         this.form.tag = ''
                        this.commvisb = false
                    } else {
                        return false;
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
.picmult{
    margin-top: 20px;
}
</style>